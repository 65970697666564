@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter-Bold'), url(./Inter-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'KOMIKAX_';
  src: local('KOMIKAX_'), url(./KOMIKAX_.ttf) format('truetype');
}
@font-face {
  font-family: 'Comic_CAT';
  src: local('Comic_CAT'), url(./Comic_CAT.otf) format('truetype');
}
@font-face {
  font-family: 'AdigianaExtreme';
  src: local('AdigianaExtreme'), url(./AdigianaExtreme.ttf) format('truetype');
}